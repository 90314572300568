import React, { useState, useRef } from "react";
import { graphql, useStaticQuery, navigate } from "gatsby";
import axios from "axios";
import Spinner from "./Spinner";

const formAPIURL = `${process.env.GATSBY_CONTACT_FORM_URL}`;
const ContactFormV2 = () => {
    const sedinServices = useStaticQuery(graphql`
        {
            allContentfulServices(sort: { fields: title, order: ASC }) {
                nodes {
                    id
                    title
                }
            }
        }
    `);

    // const processServices = [
    //     ...sedinServices.allContentfulServices.nodes.filter(
    //         (service) => service.title === "Hire Ruby on Rails Developers"
    //     ),
    //     ...sedinServices.allContentfulServices.nodes.filter(
    //         (service) => service !== "Hire Ruby on Rails Developers"
    //     ),
    // ];
    const hireRORService = "Hire Ruby on Rails Developers";
    const fileterdServices = sedinServices.allContentfulServices.nodes.filter(
        (service) => service.title != hireRORService
    );
    const formEl = useRef(null);
    const [formSubmitError, setSubmitError] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [stepForm, setFormState] = useState({
        service: "",
        name: "",
        email: "",
        phone: "",
        message: "",
        about: "",
        terms: false,
    });
    const [isSubmitted, setSubmit] = useState(false);
    const [validationErrors, setValidationError] = useState({
        nameError: "",
        emailError: "",
        phoneError: "",
        serviceError: "",
        aboutError: "",
        termsError: "",
    });

    function inputsHandler(e) {
        setFormState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    }

    function formValidator() {
        let nameError = "";
        let emailError = "";
        let phoneError = "";
        let serviceError = "";
        let aboutError = "";
        let termsError = "";

        if (!/^[a-zA-Z ]+$/.test(stepForm.name)) nameError = "Invalid Name";
        if (
            !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                stepForm.email
            )
        )
            emailError = "Invalid Email";
        if (!/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(stepForm.phone))
            phoneError = "Invalid Phone number";
        if (!stepForm.name) nameError = "Name is required";
        if (!stepForm.email) emailError = "Email is required";
        if (!stepForm.phone) phoneError = "Phone number is required";
        if (!stepForm.service) serviceError = "Service is required";
        if (!stepForm.about) aboutError = "How did you hear about us?";
        if (!stepForm.terms) termsError = "Kindly agree to the Privacy Policy";
        if (nameError || emailError || phoneError || serviceError || termsError || aboutError) {
            setValidationError({
                nameError,
                emailError,
                phoneError,
                serviceError,
                termsError,
                aboutError,
            });
            return false;
        }
        return true;
    }

    function handleFormSubmit(e) {
        e.preventDefault();
        setSubmit(true);
        const isValid = formValidator();
        if (isValid) {
            setLoading(true);
            // reset validation errors
            setValidationError({
                nameError: "",
                emailError: "",
                phoneError: "",
                serviceError: "",
                aboutError: "",
                termsError: "",
            });
            setSubmitError("");
            const formdata = stepForm;
            axios
                .post(formAPIURL, formdata, {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then((resp) => {
                    setSubmit(false);
                    setFormState({
                        service: "",
                        name: "",
                        email: "",
                        phone: "",
                        about: "",
                        message: "",
                    });
                    setLoading(false);
                    navigate("/contact/thank-you/");
                })
                .catch((err) => {
                    setSubmit(false);
                    setLoading(false);
                    setSubmitError("Oops, Something went wrong. Please try again");
                });
        }
    }

    return (
        <div className="contact-form-v2__wrapper">
            <div className="contact-form-v2__container">
                <div className="contact-form-v2__title">What are you interested in?</div>
                <form
                    onSubmit={(e) => handleFormSubmit(e)}
                    ref={formEl}
                    className="contact-form-v2__form"
                    noValidate
                    autoComplete="off"
                >
                    <div className="input-row">
                        <div className="mat-group">
                            <input
                                type="text"
                                className={`${
                                    validationErrors.nameError && isSubmitted ? "error" : ""
                                }`}
                                name="name"
                                required
                                onChange={(e) => inputsHandler(e)}
                                autoComplete="off"
                                value={stepForm.name}
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label htmlFor="name">Name*</label>
                            <div className="error-resp">{validationErrors.nameError}</div>
                        </div>
                        <div className="mat-group">
                            <input
                                type="text"
                                className={`${
                                    validationErrors.emailError && isSubmitted ? "error" : ""
                                }`}
                                name="email"
                                required
                                onChange={(e) => inputsHandler(e)}
                                value={stepForm.email}
                                autoComplete="off"
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label htmlFor="email">Business Email*</label>
                            <div className="error-resp">{validationErrors.emailError}</div>
                        </div>
                    </div>
                    <div className="input-row">
                        <div className="mat-group">
                            <input
                                type="text"
                                className={`${
                                    validationErrors.phoneError && isSubmitted ? "error" : ""
                                }`}
                                name="phone"
                                required
                                onChange={(e) => inputsHandler(e)}
                                value={stepForm.phone}
                                autoComplete="off"
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label htmlFor="phone">Phone number*</label>
                            <div className="error-resp">{validationErrors.phoneError}</div>
                        </div>
                        <div className="mat-group">
                            <select
                                name="service"
                                className={`${
                                    validationErrors.serviceError && isSubmitted ? "error" : ""
                                }`}
                                required
                                onChange={(e) => inputsHandler(e)}
                                value={stepForm.service}
                            >
                                <option defaultValue="" disabled hidden></option>
                                <option value={hireRORService}>{hireRORService}</option>
                                {/* {sedinServices.allContentfulServices.nodes.map((service) => (
                                    <option key={service.id} value={service.title}>
                                        {service.title}
                                    </option>
                                ))} */}
                                {/* {processServices.map((service) => (
                                    <option key={service.id} value={service.title}>
                                        {service.title}
                                    </option>
                                ))} */}
                                {fileterdServices.map((service) => (
                                    <option key={service.id} value={service.title}>
                                        {service.title}
                                    </option>
                                ))}
                                <option value="Others">Others</option>
                            </select>
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label htmlFor="service">Service*</label>
                            <div className="error-resp">{validationErrors.serviceError}</div>
                        </div>
                    </div>
                    <div className="mat-group">
                        <select
                            name="about"
                            className={`${
                                validationErrors.aboutError && isSubmitted ? "error" : ""
                            }`}
                            required
                            onChange={(e) => inputsHandler(e)}
                            value={stepForm.about}
                        >
                            <option defaultValue="" disabled hidden></option>
                            <option value="Linkedin">Linkedin</option>
                            <option value="Clutch.co">Clutch.co</option>
                            <option value="Google search">Google search</option>
                            <option value="Others">Others</option>
                        </select>
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label htmlFor="service">How did you hear about us?*</label>
                        <div className="error-resp">{validationErrors.aboutError}</div>
                    </div>
                    <div className="mat-group">
                        <textarea
                            rows={5}
                            type="text"
                            name="message"
                            required
                            onChange={(e) => inputsHandler(e)}
                            value={stepForm.message}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label htmlFor="message">Message</label>
                    </div>
                    <div className="mat-group ">
                        <div className="terms-condition-block">
                            <input
                                type="checkbox"
                                name="terms"
                                required="required"
                                onChange={(e) => inputsHandler(e)}
                                className="terms-conditions"
                            />
                            {/* <span className="checkmark"></span> */}
                            <span className="terms-conditions-text text-p6">
                                "I agree to share my information with Sedin and understand it will
                                be used as described in their Privacy Policy."
                            </span>
                        </div>
                        <div className="error-resp">{validationErrors.termsError}</div>
                    </div>
                    <div className="form-error">{formSubmitError}</div>
                    <button className="sdn-cta-btn next" type="submit">
                        <span>Submit</span>
                        <Spinner loading={isLoading} />
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ContactFormV2;
