import React, { useRef, useState } from "react";
import "../styles/main.scss";
import { Helmet } from "react-helmet";
import axios from "axios";
import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import EAM360LOGO from "../images/IBM_Event/EAM-LOGO.svg";
import SedinWhiteLogo from "../images/salesforce/sedin-white-logo.svg";
import MaximoHeroSVG from "../images/IBM_Event/maximo-hero-1.svg";
import MaximoKeyStrokeSVG from "../images/IBM_Event/Key-Stroke.svg";

const MaximoSuiteEvent = () => {
    const formAPIURL = `${process.env.GATSBY_BLOG_FORM_URL}`;

    const [stepForm, setFormState] = useState({
        fullName: "",
        companyName: "",
        email: "",
        terms: false,
    });
    const [isLoading, setLoading] = useState(false);
    const formEl = useRef(null);
    const [formSubmitError, setSubmitError] = useState("");
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formErrors = {};

        if (!stepForm.companyName) {
            formErrors.companyName = "Company Name is required";
        }
        // Email validation
        if (!stepForm.email) {
            formErrors.email = "Email is required.";
        } else if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(stepForm.email)) {
            formErrors.email = "Please enter a valid email address.";
        } else {
            // Restrict free email domains
            const restrictedDomains = [
                "gmail.com",
                "yahoo.com",
                "icloud.com",
                "hotmail.com",
                "outlook.com",
            ];
            const emailDomain = stepForm.email.split("@")[1]?.toLowerCase(); // Convert to lowercase

            if (restrictedDomains.includes(emailDomain)) {
                formErrors.email =
                    "Personal email ID are not allowed. Please use a business email.";
            }
        }
        // Terms validation
        if (!stepForm.terms) {
            formErrors.terms = "You must agree to the terms.";
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };
    function handleFormSubmit(e) {
        e.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            setLoading(true);
            const formdata = stepForm;
            axios
                .post(formAPIURL, formdata, {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then((resp) => {
                    setFormState({
                        fullName: "",
                        email: "",
                        companyName: "",
                        terms: false,
                    });
                    setLoading(false);
                    const maximoWebinarSlug =
                        "webinar-navigating-maximo-suite-application-upgrade-challenges-lessons-Jan-2025";
                    navigate(`/contact/thank-you/?source=${encodeURIComponent(maximoWebinarSlug)}`);
                })
                .catch((err) => {
                    setLoading(false);
                    setSubmitError("Oops, Something went wrong. Please try again");
                });
        }
        console.log("formdata ", stepForm);
    }
    function inputsHandler(e) {
        const { name, value, type, checked } = e.target;

        setFormState((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,
        }));
    }
    // scroll to form section
    const scrollToForm = () => {
        const formSection = document.getElementById("maximo-e-form");
        if (formSection) {
            formSection.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <section></section>
            <section className="maximo-event-hero">
                <div className="container">
                    <img src={SedinWhiteLogo} alt="" loading="lazy" className="sedin-white-logo" />
                </div>
                <div className="container maximo-hero-e-container">
                    <div className="maximo-hero-e-grid">
                        <div className="maximo-hero-e-grid-left">
                            <div className="maximo-hero-e-grid-left__text">
                                <p className="text-p3 text-fw-medium text-white gap-1x custom-clr">
                                    Navigate the MAS upgrade journey with Confidence
                                </p>
                                <h1 className="text-h1 text-fw-medium text-white">
                                    An Exclusive Webinar:
                                </h1>
                                <h1 className="text-h1 text-fw-medium text-white custom-clr gap-1x">
                                    Navigating the MAS Upgrade: Challenges & Lessons Learnt
                                </h1>
                                <div className="date-time gap-4x">
                                    <p className="text-p3 text-white">Date: 30 Jan 2025</p>
                                    <p className="text-p3 text-white">Time: 4 pm EST</p>
                                </div>
                                <button
                                    className="text-p2 custom-btn"
                                    type="submit"
                                    onClick={scrollToForm}
                                >
                                    Register Now
                                </button>
                            </div>
                        </div>
                        <div className="maximo-hero-e-grid-right">
                            <div className="maximo-hero-e-grid-right__image">
                                <img src={MaximoHeroSVG} alt="" className="" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container maximo-speaker">
                    <div className="maximo-speaker__grid">
                        <div className="maximo-speaker__grid-left">
                            <h1 className="text-h2 text-fw-medium custom-clr">
                                Join Prashant Sharma
                            </h1>
                            <p className="text-p3 text-white">
                                Delivery Manager, IBM Champion 2022 - 2023 - 2024
                            </p>
                        </div>
                        <div className="maximo-speaker__grid-right">
                            <div className="text-white text-p3">
                                <p className="gap-05x">
                                    Upgrading from IBM Maximo 7.6 to MAS SaaS can feel overwhelming.
                                    But with the right guidance and insights, you can transform
                                    challenges into opportunities for growth and efficiency.
                                </p>
                                <p>
                                    Gain practical knowledge, proven strategies, and valuable
                                    lessons to help your organization streamline the transition and
                                    prepare for the future of asset management.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container maximo-why-attend">
                    <div className="maximo-why-attend__text">
                        <h1 className="text-h1 gap-4x">Why attend?</h1>
                    </div>
                    <div className="maximo-why-attend__grid gap-4x">
                        <div className="maximo-why-attend__grid-item">
                            <p className="text-p1 text-fw-medium gap-05x">
                                Decode the MAS SaaS Upgrade Path
                            </p>
                            <p className="text-p4">
                                Learn a clear, step-by-step approach to upgrading from Maximo 7.6.
                            </p>
                        </div>
                        <div className="maximo-why-attend__grid-item">
                            <p className="text-p1 text-fw-medium gap-05x">
                                Solve Migration Challenges
                            </p>
                            <p className="text-p4">
                                Explore solutions to common obstacles and ensure a seamless
                                transition.
                            </p>
                        </div>
                        <div className="maximo-why-attend__grid-item">
                            <p className="text-p1 text-fw-medium gap-05x">Embrace Innovation</p>
                            <p className="text-p4">
                                Discover advanced capabilities that boost efficiency and
                                scalability.
                            </p>
                        </div>
                        <div className="maximo-why-attend__grid-item">
                            <p className="text-p1 text-fw-medium gap-05x">
                                Plan for Future Readiness
                            </p>
                            <p className="text-p4">
                                Gain insights from lessons learned to build a robust and agile asset
                                management system.
                            </p>
                        </div>
                    </div>
                    <div className="maximo-why-attend__text">
                        <button className="text-p2 custom-btn" type="submit" onClick={scrollToForm}>
                            Register Now
                        </button>
                    </div>
                </div>
            </section>
            <section className="maximo-key">
                <div className="container maximo-key-container">
                    <div className="maximo-key__text gap-1x">
                        <h1 className="text-h1">Key takeaways</h1>
                        <p className="text-p3">By the end of this webinar, you will</p>
                    </div>
                    <div className="maximo-key__image">
                        <img src={MaximoKeyStrokeSVG} alt="" loading="lazy" />
                    </div>
                    <div className="maximo-key__grid gap-2x">
                        <div className="maximo-key__grid-item">
                            <p className="text-p3">Understand the MAS SaaS upgrade roadmap.</p>
                        </div>
                        <div className="maximo-key__grid-item">
                            <p className="text-p3">
                                Learn how to address and overcome migration challenges.
                            </p>
                        </div>
                        <div className="maximo-key__grid-item">
                            <p className="text-p3">
                                Gain actionable insights into innovations driving efficiency.
                            </p>
                        </div>
                        <div className="maximo-key__grid-item">
                            <p className="text-p3">Be equipped to ensure a smooth transition.</p>
                        </div>
                    </div>
                    {/* <div className="maximo-key__text">
                        <button className="text-p2 custom-btn">Register Now</button>
                    </div> */}
                </div>
            </section>
            <section className="IBM-form-sec maximo-event-form">
                <div className="container">
                    <div className="IBM-form-sec__text">
                        <h1 className="text-h1">Reserve your spot now!</h1>
                    </div>
                    <div className="IBM-form-sec__form-grid">
                        <form
                            id="maximo-e-form"
                            className="reserve-form"
                            // onSubmit={handleFormSubmit}
                            onSubmit={(e) => handleFormSubmit(e)}
                            ref={formEl}
                            noValidate
                            autoComplete="off"
                        >
                            <div className="form-field">
                                <input
                                    type="text"
                                    id="fullName"
                                    name="fullName"
                                    placeholder="Full name"
                                    value={stepForm.fullName}
                                    onChange={inputsHandler}
                                    required
                                />
                                {/* <label htmlFor="fullName">Full name</label> */}
                            </div>
                            <div className="form-field">
                                <input
                                    type="text"
                                    id="companyName"
                                    name="companyName"
                                    placeholder="Company Name"
                                    value={stepForm.companyName}
                                    onChange={inputsHandler}
                                    required
                                />
                                {/* <label htmlFor="companyName">Company Name</label> */}
                                {errors.companyName && (
                                    <p className="error-text">{errors.companyName}</p>
                                )}
                            </div>
                            <div className="form-field">
                                <input
                                    type="email"
                                    id="emailAddress"
                                    name="email"
                                    placeholder="Business Email"
                                    value={stepForm.email}
                                    onChange={inputsHandler}
                                    required
                                />
                                {errors.email && <p className="error-text">{errors.email}</p>}
                                {/* <label htmlFor="emailAddress">Email Address</label> */}
                            </div>
                            <div className="form-field terms-field">
                                <input
                                    type="checkbox"
                                    id="terms"
                                    name="terms"
                                    checked={stepForm.terms}
                                    onChange={inputsHandler}
                                />
                                <label
                                    htmlFor="terms"
                                    className="terms-conditions-text text-p4 text-clr-secondary text-fw-regular"
                                >
                                    "I agree to share my information with Sedin and understand it
                                    will be used as described in their Privacy Policy."
                                </label>
                                {errors.terms && <p className="error-text">{errors.terms}</p>}
                            </div>
                            <button type="submit" className="text-p2 custom-btn">
                                Register Now
                            </button>
                        </form>
                    </div>
                </div>
            </section>
            <section className="maximo-event">
                <div className="container">
                    <div className="maximo-event__content">
                        <img
                            src={SedinWhiteLogo}
                            alt="sedin-logo"
                            loading="lazy"
                            className="sedin-logo-text gap-05x"
                        />
                        <p className="footer-para gap-4x">
                            Reach out to <a href="mailto:sales@eam360.com">sales@eam360.com</a>, If
                            you have any questions.
                        </p>
                        <p className="footer-rights">
                            © 2025 Sedin Technologies Private Limited | All Rights Reserved
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MaximoSuiteEvent;
