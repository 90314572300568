import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { LogoAnimationContext } from "../../context/LogoAnimationContext";
import ProjectGrid from "../../components/ProjectGrid";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import ClientsBlockV2 from "../../components/ClientsBlockV2";
import { useLocation } from "@reach/router";

const ContactThankYou = () => {
    const { setCurrentAnimationName } = useContext(LogoAnimationContext);
    const [isWebinarSlug, setIsWebinarSlug] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setCurrentAnimationName("send");
        const params = new URLSearchParams(window.location.search);
        const source = params.get("source");
        const webinarSlug =
            "webinar-navigating-maximo-suite-application-upgrade-challenges-lessons-Jan-2025";
        if (source === webinarSlug) {
            setIsWebinarSlug(true);
        }
    }, [location.search]);
    const featuredCaseStudies = useStaticQuery(graphql`
        {
            allContentfulCasestudies(sort: { order: DESC, fields: createdAt }, limit: 6) {
                nodes {
                    id
                    name
                    logo {
                        gatsbyImageData(placeholder: BLURRED, quality: 100)
                        title
                    }
                    services {
                        id
                        title
                    }
                    description {
                        description
                    }
                    doneBy {
                        name
                    }
                    backgroundType
                    backgroundMedia {
                        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
                        title
                        file {
                            contentType
                            url
                        }
                    }
                    colorTheme
                    thumbnailTheme
                    slug
                }
            }
        }
    `);

    return (
        <Layout>
            <section>
                <div className="career-form__end-card container container--py">
                    <div className="career-form__end-card__text">
                        {isWebinarSlug ? (
                            <>
                                <h2 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                                    Thank you for signing up!
                                </h2>
                                <p className="text-p1 text-fw-regular text-clr-secondary gap-05x">
                                    We look forward to having you join us and hearing your questions
                                    and feedback.
                                </p>
                                <p className="text-p1 text-fw-regular text-clr-secondary">
                                    The joining link will be shared with you shortly, please check
                                    the spam folder if it has not delivered.
                                </p>
                            </>
                        ) : (
                            <>
                                <h2 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                                    Thanks For Being Awesome!
                                </h2>
                                <p className="text-p1 text-fw-regular text-clr-secondary">
                                    We have received your message and would like to thank you for
                                    writing to us. One of our experts will reach out to you soon.
                                </p>
                            </>
                        )}
                    </div>
                </div>
            </section>
            <section className="home-projects">
                <div className="container container--py">
                    <div className="text">
                        <h2 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                            50+ global enterprises fuel their growth engines with Sedin experts
                            everyday
                        </h2>
                        <p className="text-p2 text-fw-regular text-clr-secondary gap-4x">
                            We work together with enterprises, leaders in every industry across the
                            globe to unlock their growth potential in extraordinary ways.
                        </p>
                    </div>
                    <div className="gap-3x">
                        <ProjectGrid
                            CaseStudiesData={featuredCaseStudies.allContentfulCasestudies.nodes}
                        />
                    </div>
                    <Link to="/casestudies/" className="sdn-link">
                        View projects
                    </Link>
                </div>
            </section>
            <section className="home-clients">
                <div className="container container--last">
                    <ClientsBlockV2 />
                </div>
            </section>
        </Layout>
    );
};

export default ContactThankYou;
