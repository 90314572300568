exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-csr-breaking-barriers-supporting-young-girls-index-jsx": () => import("./../../../src/pages/about/csr/breaking-barriers-supporting-young-girls/index.jsx" /* webpackChunkName: "component---src-pages-about-csr-breaking-barriers-supporting-young-girls-index-jsx" */),
  "component---src-pages-about-csr-building-dreams-empowering-education-index-jsx": () => import("./../../../src/pages/about/csr/building-dreams-empowering-education/index.jsx" /* webpackChunkName: "component---src-pages-about-csr-building-dreams-empowering-education-index-jsx" */),
  "component---src-pages-about-csr-changing-lives-spreading-smiles-index-jsx": () => import("./../../../src/pages/about/csr/changing-lives-spreading-smiles/index.jsx" /* webpackChunkName: "component---src-pages-about-csr-changing-lives-spreading-smiles-index-jsx" */),
  "component---src-pages-about-csr-fostering-dreams-igniting-learning-index-jsx": () => import("./../../../src/pages/about/csr/fostering-dreams-igniting-learning/index.jsx" /* webpackChunkName: "component---src-pages-about-csr-fostering-dreams-igniting-learning-index-jsx" */),
  "component---src-pages-about-csr-index-jsx": () => import("./../../../src/pages/about/csr/index.jsx" /* webpackChunkName: "component---src-pages-about-csr-index-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-about-life-at-sedin-index-jsx": () => import("./../../../src/pages/about/life-at-sedin/index.jsx" /* webpackChunkName: "component---src-pages-about-life-at-sedin-index-jsx" */),
  "component---src-pages-about-newsroom-index-jsx": () => import("./../../../src/pages/about/newsroom/index.jsx" /* webpackChunkName: "component---src-pages-about-newsroom-index-jsx" */),
  "component---src-pages-about-our-advisors-index-jsx": () => import("./../../../src/pages/about/our-advisors/index.jsx" /* webpackChunkName: "component---src-pages-about-our-advisors-index-jsx" */),
  "component---src-pages-ai-chapter-gen-ai-case-studies-index-jsx": () => import("./../../../src/pages/ai-chapter/genAI-case-studies/index.jsx" /* webpackChunkName: "component---src-pages-ai-chapter-gen-ai-case-studies-index-jsx" */),
  "component---src-pages-ai-chapter-index-jsx": () => import("./../../../src/pages/ai-chapter/index.jsx" /* webpackChunkName: "component---src-pages-ai-chapter-index-jsx" */),
  "component---src-pages-ai-chapter-leverage-gen-ai-index-jsx": () => import("./../../../src/pages/ai-chapter/leverage-genAI/index.jsx" /* webpackChunkName: "component---src-pages-ai-chapter-leverage-gen-ai-index-jsx" */),
  "component---src-pages-ai-chapter-solutions-api-systems-integration-sita-index-jsx": () => import("./../../../src/pages/ai-chapter/solutions/api-systems-integration-sita/index.jsx" /* webpackChunkName: "component---src-pages-ai-chapter-solutions-api-systems-integration-sita-index-jsx" */),
  "component---src-pages-ai-chapter-solutions-index-jsx": () => import("./../../../src/pages/ai-chapter/solutions/index.jsx" /* webpackChunkName: "component---src-pages-ai-chapter-solutions-index-jsx" */),
  "component---src-pages-ai-chapter-solutions-intelligent-document-processing-vidura-index-jsx": () => import("./../../../src/pages/ai-chapter/solutions/intelligent-document-processing-vidura/index.jsx" /* webpackChunkName: "component---src-pages-ai-chapter-solutions-intelligent-document-processing-vidura-index-jsx" */),
  "component---src-pages-ai-chapter-solutions-intelligent-music-cover-artwork-platform-index-jsx": () => import("./../../../src/pages/ai-chapter/solutions/intelligent-music-cover-artwork-platform/index.jsx" /* webpackChunkName: "component---src-pages-ai-chapter-solutions-intelligent-music-cover-artwork-platform-index-jsx" */),
  "component---src-pages-ai-chapter-solutions-structured-database-systems-sara-index-jsx": () => import("./../../../src/pages/ai-chapter/solutions/structured-database-systems-sara/index.jsx" /* webpackChunkName: "component---src-pages-ai-chapter-solutions-structured-database-systems-sara-index-jsx" */),
  "component---src-pages-bim-services-jsx": () => import("./../../../src/pages/bim-services.jsx" /* webpackChunkName: "component---src-pages-bim-services-jsx" */),
  "component---src-pages-casestudies-index-jsx": () => import("./../../../src/pages/casestudies/index.jsx" /* webpackChunkName: "component---src-pages-casestudies-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-contact-thank-you-jsx": () => import("./../../../src/pages/contact/thank-you.jsx" /* webpackChunkName: "component---src-pages-contact-thank-you-jsx" */),
  "component---src-pages-csr-policy-jsx": () => import("./../../../src/pages/csr-policy.jsx" /* webpackChunkName: "component---src-pages-csr-policy-jsx" */),
  "component---src-pages-divisions-index-jsx": () => import("./../../../src/pages/divisions/index.jsx" /* webpackChunkName: "component---src-pages-divisions-index-jsx" */),
  "component---src-pages-events-bengaluru-tech-summit-2024-jsx": () => import("./../../../src/pages/events/bengaluru-tech-summit-2024.jsx" /* webpackChunkName: "component---src-pages-events-bengaluru-tech-summit-2024-jsx" */),
  "component---src-pages-events-digital-transformation-week-global-2023-jsx": () => import("./../../../src/pages/events/digital-transformation-week-global-2023.jsx" /* webpackChunkName: "component---src-pages-events-digital-transformation-week-global-2023-jsx" */),
  "component---src-pages-events-dsf-24-boston-jsx": () => import("./../../../src/pages/events/dsf24-boston.jsx" /* webpackChunkName: "component---src-pages-events-dsf-24-boston-jsx" */),
  "component---src-pages-events-fabtech-canada-expo-2024-jsx": () => import("./../../../src/pages/events/fabtech-canada-expo-2024.jsx" /* webpackChunkName: "component---src-pages-events-fabtech-canada-expo-2024-jsx" */),
  "component---src-pages-events-fabtech-orlando-2024-jsx": () => import("./../../../src/pages/events/fabtech-orlando-2024.jsx" /* webpackChunkName: "component---src-pages-events-fabtech-orlando-2024-jsx" */),
  "component---src-pages-events-gartner-it-symposium-2023-jsx": () => import("./../../../src/pages/events/gartner-it-symposium-2023.jsx" /* webpackChunkName: "component---src-pages-events-gartner-it-symposium-2023-jsx" */),
  "component---src-pages-events-gitex-2023-jsx": () => import("./../../../src/pages/events/gitex-2023.jsx" /* webpackChunkName: "component---src-pages-events-gitex-2023-jsx" */),
  "component---src-pages-events-gitex-2024-jsx": () => import("./../../../src/pages/events/gitex-2024.jsx" /* webpackChunkName: "component---src-pages-events-gitex-2024-jsx" */),
  "component---src-pages-events-index-jsx": () => import("./../../../src/pages/events/index.jsx" /* webpackChunkName: "component---src-pages-events-index-jsx" */),
  "component---src-pages-events-manufacturing-it-summit-mumbai-2024-jsx": () => import("./../../../src/pages/events/manufacturing-it-summit-mumbai-2024.jsx" /* webpackChunkName: "component---src-pages-events-manufacturing-it-summit-mumbai-2024-jsx" */),
  "component---src-pages-events-opentext-world-2024-jsx": () => import("./../../../src/pages/events/opentext-world-2024.jsx" /* webpackChunkName: "component---src-pages-events-opentext-world-2024-jsx" */),
  "component---src-pages-events-opentextworld-index-jsx": () => import("./../../../src/pages/events/opentextworld/index.jsx" /* webpackChunkName: "component---src-pages-events-opentextworld-index-jsx" */),
  "component---src-pages-events-wit-dreamin-2024-jsx": () => import("./../../../src/pages/events/wit-dreamin-2024.jsx" /* webpackChunkName: "component---src-pages-events-wit-dreamin-2024-jsx" */),
  "component---src-pages-ibm-leadership-conference-jsx": () => import("./../../../src/pages/IBM-Leadership-Conference.jsx" /* webpackChunkName: "component---src-pages-ibm-leadership-conference-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-insights-blog-index-jsx": () => import("./../../../src/pages/insights/blog/index.jsx" /* webpackChunkName: "component---src-pages-insights-blog-index-jsx" */),
  "component---src-pages-insights-index-jsx": () => import("./../../../src/pages/insights/index.jsx" /* webpackChunkName: "component---src-pages-insights-index-jsx" */),
  "component---src-pages-opportunities-apply-index-jsx": () => import("./../../../src/pages/opportunities/apply/index.jsx" /* webpackChunkName: "component---src-pages-opportunities-apply-index-jsx" */),
  "component---src-pages-opportunities-apply-thank-you-jsx": () => import("./../../../src/pages/opportunities/apply/thank-you.jsx" /* webpackChunkName: "component---src-pages-opportunities-apply-thank-you-jsx" */),
  "component---src-pages-opportunities-index-jsx": () => import("./../../../src/pages/opportunities/index.jsx" /* webpackChunkName: "component---src-pages-opportunities-index-jsx" */),
  "component---src-pages-partners-index-jsx": () => import("./../../../src/pages/partners/index.jsx" /* webpackChunkName: "component---src-pages-partners-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-qa-services-index-jsx": () => import("./../../../src/pages/qa-services/index.jsx" /* webpackChunkName: "component---src-pages-qa-services-index-jsx" */),
  "component---src-pages-salesforce-consulting-services-jsx": () => import("./../../../src/pages/salesforce-consulting-services.jsx" /* webpackChunkName: "component---src-pages-salesforce-consulting-services-jsx" */),
  "component---src-pages-salesforce-partner-jsx": () => import("./../../../src/pages/salesforce-partner.jsx" /* webpackChunkName: "component---src-pages-salesforce-partner-jsx" */),
  "component---src-pages-sedin-engineering-jsx": () => import("./../../../src/pages/sedin-engineering.jsx" /* webpackChunkName: "component---src-pages-sedin-engineering-jsx" */),
  "component---src-pages-sedstart-jsx": () => import("./../../../src/pages/sedstart.jsx" /* webpackChunkName: "component---src-pages-sedstart-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-pages-us-cad-services-jsx": () => import("./../../../src/pages/us/cad-services.jsx" /* webpackChunkName: "component---src-pages-us-cad-services-jsx" */),
  "component---src-pages-webinar-navigating-maximo-suite-application-upgrade-challenges-lessons-jan-2025-jsx": () => import("./../../../src/pages/webinar-navigating-maximo-suite-application-upgrade-challenges-lessons-Jan-2025.jsx" /* webpackChunkName: "component---src-pages-webinar-navigating-maximo-suite-application-upgrade-challenges-lessons-jan-2025-jsx" */),
  "component---src-templates-blog-category-jsx": () => import("./../../../src/templates/BlogCategory.jsx" /* webpackChunkName: "component---src-templates-blog-category-jsx" */),
  "component---src-templates-blog-detail-jsx": () => import("./../../../src/templates/BlogDetail.jsx" /* webpackChunkName: "component---src-templates-blog-detail-jsx" */),
  "component---src-templates-campaign-jsx": () => import("./../../../src/templates/Campaign.jsx" /* webpackChunkName: "component---src-templates-campaign-jsx" */),
  "component---src-templates-case-study-detail-jsx": () => import("./../../../src/templates/CaseStudyDetail.jsx" /* webpackChunkName: "component---src-templates-case-study-detail-jsx" */),
  "component---src-templates-division-detail-jsx": () => import("./../../../src/templates/DivisionDetail.jsx" /* webpackChunkName: "component---src-templates-division-detail-jsx" */),
  "component---src-templates-global-campaign-jsx": () => import("./../../../src/templates/GlobalCampaign.jsx" /* webpackChunkName: "component---src-templates-global-campaign-jsx" */),
  "component---src-templates-maximo-campaign-jsx": () => import("./../../../src/templates/MaximoCampaign.jsx" /* webpackChunkName: "component---src-templates-maximo-campaign-jsx" */),
  "component---src-templates-partner-detail-jsx": () => import("./../../../src/templates/PartnerDetail.jsx" /* webpackChunkName: "component---src-templates-partner-detail-jsx" */),
  "component---src-templates-service-detail-jsx": () => import("./../../../src/templates/ServiceDetail.jsx" /* webpackChunkName: "component---src-templates-service-detail-jsx" */),
  "component---src-templates-sub-services-jsx": () => import("./../../../src/templates/SubServices.jsx" /* webpackChunkName: "component---src-templates-sub-services-jsx" */),
  "component---src-templates-tableau-campaign-jsx": () => import("./../../../src/templates/TableauCampaign.jsx" /* webpackChunkName: "component---src-templates-tableau-campaign-jsx" */),
  "component---src-templates-tag-category-jsx": () => import("./../../../src/templates/TagCategory.jsx" /* webpackChunkName: "component---src-templates-tag-category-jsx" */)
}

